import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchDimensionsRequest, fetchScopesRequest, getSalesConversionName, getSalesConversionObject, setDateRangeReports, setListSegmentReport } from '../../../../actions/subscriber';
import { callTokenApi } from '../../../../apiCaller';
import { API_CLIENT_DEFAULT_REPORT_DATE_RANGE_CONFIGS, API_GET_ECO_ITEM_CATEGORIES, BQ_JOB_STATUS, CLIENT_GET_SAVED_REPORT_BY_ID, CLIENT_GET_SAVED_VIEWS, DEFAULT_DATE_FORMAT, FILTER_DATA_TYPES, REPORT_DATA_TYPES, REPORT_NAMES, SESSION_STORAGE_REPORT_DATE_RANGE } from '../../../../constants';
import {
     DATE_SCOPE,
     PERIOD_TYPES,
     PERIOD_TYPES_NEW,
     REPORT_TAB_TYPES,
     ROLLING_DATE_RANGE,
     SAVE_REPORT_DATE_OPTION,
     SCOPED,
     // TYPE_PROPERTIES_CUSTOM_FILTER_NEED_REMOVE,
     VARIABLE_OPERATORS_REPORT,
     WEIGHT_TYPES,
} from '../../../../constants/analytics';
import { camelCase, decodeObj, getSegmentField, handleAddMetricSalesConversion, handleAddMetricSalesConvNameIndividual } from '../../../../utils';
import CenterSpinner from '../../../general/Loadings/CenterSpinner';
import ChartReport from './chart-report';
import CustomDatePicker from './CustomDatePicker';
import MenuReport from './MenuReport';
import ReportTableV2 from './ReportTable-V2';
import ScopeDropdown from './ScopeDropdown';
import useFirstFetchReportData from './useFirstFetchReportData';
import useUtmArray from './useUtmArray';
import AddSegments from './segments/AddSegments';
import { VIEW_TYPE } from '../../../../constants/reportView';
import { useHistory } from "react-router-dom";
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import CSubheader from '../../../migration/CSubheader';
import BreadcrumbRouter from '../../../general/breadcrumb/BreadcrumbRouter';
dayjs.extend(quarterOfYear)

export const EventExplorerContext = createContext();
export const handleGetOption = ({ option, type = '', filter = [], reportName, defaultArr, weight, menuFilter, isDefaultMetrics = false }) => {
     let newOptions = [];
     let optionReport = option;
     const hasDefaultArrReports = [
          REPORT_NAMES.UTM_REPORT,
          REPORT_NAMES.ALL_PAGE_REPORT,
          REPORT_NAMES.LANDING_PAGE_REPORT,
          REPORT_NAMES.EXIT_PAGE_REPORT,
          REPORT_NAMES.BY_LANDING_PAGE_REPORT,
          REPORT_NAMES.BY_UTM_REPORT,
          REPORT_NAMES.FORM_CATEGORY_REPORT,
          REPORT_NAMES.SOURCE_REPORT,
          REPORT_NAMES.BY_SOURCE_REPORT,
          REPORT_NAMES.CHANNEL_REPORT,
          REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT,
          REPORT_NAMES.ECOMMERCE_ITEMS,
          REPORT_NAMES.BY_CONVERSION,
          REPORT_NAMES.BY_PROPERTY,
          REPORT_NAMES.SALES_CONVERSION_BY_CONVERSION,
          REPORT_NAMES.SALES_CONVERSION_BY_PROPERTY
     ];

     if (isDefaultMetrics && type === 'defaultMetric') {
          optionReport = option.filter((_item) => _item.isDefault);
     }

     if (hasDefaultArrReports.includes(reportName) && type === 'defaultDimension') {
          optionReport = defaultArr;
     }
     // add select all
     if (type === 'option' && menuFilter !== 'filterDimension' && ![REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY, REPORT_NAMES.SALES_CONVERSION_BY_CONVERSION, REPORT_NAMES.SALES_CONVERSION_BY_PROPERTY].includes(reportName)) {
          newOptions.push({ label: 'Select All', value: '' });
     }
     optionReport && optionReport.forEach((item) => {
          // report conversion source attribution case 1: not select weights default case 2: weights select when model (weight) have select
          if (
               reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT &&
               (!WEIGHT_TYPES.find((el) => el.key === item.key) || (weight && weight.value && item.key === weight.value))
          ) {
               if (type === 'weight') {
                    newOptions.push({ label: item.label, key: item.key });
               } else {
                    newOptions.push({ label: item.label, value: item.key });
               }
          } else if (reportName !== REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
               const newOption = { label: item.label, value: item.key, section: item?.section, description: item?.description }
               if (item?.sectionParent) {
                    newOption.sectionParent = item?.sectionParent
               }
               newOptions.push(newOption);
          }
     });
     // select all item show no options
     if (filter.length > 0 && newOptions.some((item) => item.label === 'Select All') && filter.length === newOptions.length - 1) {
          newOptions = [];
     }
     return newOptions;
};

export const handleGetSortSegment = ({ listSegmentReportApply, filterMetric }) => {
     if (!filterMetric || filterMetric.length === 0) {
          return {};
     }

     if (listSegmentReportApply.length === 0) {
          return {
               fieldName: filterMetric[0].value,
               isDesc: true,
          };
     }
     if (listSegmentReportApply.length > 0) {
          listSegmentReportApply = listSegmentReportApply.sort((a, b) => new Date(a.orderBy) - new Date(b.orderBy));
          const newFilterMetrics =
               filterMetric &&
               filterMetric.map((e) => ({
                    value: e.value ? `${getSegmentField(listSegmentReportApply[0].id, e.value.split('_').pop())}` : `${getSegmentField(listSegmentReportApply[0].id, e.key.split('_').pop())}`,
                    label: e.label,
               }));
          if (newFilterMetrics[0]) {
               return {
                    fieldName: newFilterMetrics[0].value,
                    isDesc: true,
               };
          } else {
               return {}
          }
     }
};

const useDataParam = () => {
     const location = useLocation();
     let dataParam = new URLSearchParams(location.search).get('data');
     let dataParamValue;
     let draftReport;
     if (dataParam) {
          const dataParamRaw = decodeObj(dataParam);
          const { fieldKey, reportName, accountId, isCompare = false, isProfile = false } = dataParamRaw;
          const draftReportRaw = sessionStorage.getItem('draftReportLink')

          if (!draftReportRaw) {
               return dataParamValue;
          }

          const draftReports = JSON.parse(draftReportRaw);

          if (!draftReports) {
               return dataParamValue;
          }

          draftReport = draftReports.find((item) => item.accountId === accountId && item.reportName === reportName && item.fieldKey === fieldKey && item.isCompare === isCompare && item.isProfile === isProfile);

          if (!draftReport) {
               return dataParamValue;
          }

          dataParamValue = draftReport.linkedReportData
     }

     return dataParamValue;
};

const getPropertiesByCustomDimension = ({ dimensions, properties, customDimensionsTemp, reportName, scoped, scopes, showTargetAccount }) => {
     // DL-1786

     let newProperties = [];
     const customProperties = [];
     // array need check custom properties, includes: dimensions, properties
     const arrayNeedCheck = [...dimensions, ...properties];
     const sameNamePropertyArray = [];

     customDimensionsTemp.forEach((item) => {
          const scopeItem = [];
          let target = '';
          let isSameNameProperty = false;
          const removeItem = ['ads_storage', 'Regional Consent Rule'].includes(item.name) && scoped === SCOPED.EVENT;
          let removeTargetAccount = !showTargetAccount && item.name === 'Target Account Domain';
          const sameProperty = arrayNeedCheck.find((el) => item.isCustom === false && el.label === item.name); // the same label
          const sameKeyProperty = arrayNeedCheck.find((el) => item.isCustom === false && el.key === camelCase(item.name)); // the same path
          const sameNameProperty = customDimensionsTemp.find(
               (el) => item.isCustom === false && el.name !== item.name && camelCase(el.name) === camelCase(item.name)
          ); // the same path

          item.availableScopes.forEach((el) => {
               let scope = scopes.find((e) => e.id === el.id);
               if (scope) {
                    scopeItem.push(scope.name);
               }

               if (scope && scope.name.includes(scoped)) {
                    target = el.target;
               }
          });
          if (scopeItem.find((e) => e.includes(scoped)) && sameNameProperty) {
               sameNamePropertyArray.push(item);
          }
          if (sameNamePropertyArray.length > 1) {
               // name custom report dup or many dup
               const searchTerm = camelCase(item.name);
               const searchRegex = new RegExp(searchTerm, 'i');
               const occurrences = sameNamePropertyArray.filter((obj) =>
                    Object.values(obj).some((value) => typeof value === 'string' && value.match(searchRegex))
               );
               const countOccurrences = occurrences.length || 0;
               if (countOccurrences > 1) {
                    isSameNameProperty = true;
               }
          }

          const isWebConversion = item.availableScopes.some((item) => item.isWebConversion);

          const addCustomProperty =
               scopeItem.find((e) => e.includes(scoped)) && !sameProperty && !sameKeyProperty && !isSameNameProperty && !removeItem && !removeTargetAccount;
          if (
               (reportName !== REPORT_NAMES.CONVERSION_EVENTS && addCustomProperty) ||
               (reportName === REPORT_NAMES.CONVERSION_EVENTS && isWebConversion) ||
               ((reportName === REPORT_NAMES.BY_CONVERSION || reportName === REPORT_NAMES.BY_PROPERTY) && isWebConversion)
          ) {
               newProperties.push({
                    key: `${camelCase(
                         item.name
                              .replace(/\b\w/g, (match) => match.toUpperCase())
                              .replace(/^\w/, (match) => match.toLowerCase())
                              .replace(/\s+/g, '')
                    )}Custom`,
                    label: item.name,
                    section: 'Custom Dimension',
               });
               customProperties.push({
                    ...item,
                    properties: `${camelCase(
                         item.name
                              .replace(/\b\w/g, (match) => match.toUpperCase())
                              .replace(/^\w/, (match) => match.toLowerCase())
                              .replace(/\s+/g, '')
                    )}Custom`,
                    target: target,
               });
          }
     });
     if (newProperties.length > 0) {
          newProperties = newProperties.sort((a, b) => a.key.localeCompare(b.key));
     }
     if (
          newProperties.length > 0 &&
          [
               REPORT_NAMES.SESSION_DETAILS,
               REPORT_NAMES.USER_DETAILS,
               REPORT_NAMES.PERSON_DETAILS,
               REPORT_NAMES.COMPANY_DETAILS,
               REPORT_NAMES.PERSON_DETAILS_PROFILE,
               REPORT_NAMES.EVENT_DETAILS,
               REPORT_NAMES.CONVERSION_EVENTS,
               REPORT_NAMES.BY_CONVERSION,
               REPORT_NAMES.BY_PROPERTY,
          ].includes(reportName)
     ) {
          return { newProperties: properties.concat(newProperties), customProperties: customProperties };
     } else {
          return { newProperties: properties, customProperties: [] };
     }
};
const getDrawReport = ({ accountId, reportName }) => {
     const draftReportRaw = sessionStorage.getItem('draftReport')
     let isDraft = false;
     let draftReport;
     if (!draftReportRaw) {
          return { isDraft, draftReport };
     }
     const draftReports = JSON.parse(draftReportRaw);
     if (!draftReports) {
          return { isDraft, draftReport };
     }
     draftReport = draftReports.find((item) => item.accountId === accountId && item.reportName === reportName);
     if (!draftReport) {
          return { isDraft, draftReport };
     }
     return { isDraft: true, draftReport }
}
const EventExplorer = ({ title, reportName, reportType, recordProfile, filterRecord, isFecthTableEvent = true }) => {
     const dispatch = useDispatch();
     const routes = useSelector(state => state.subscriber.routes);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { isDraft, draftReport } = getDrawReport({ accountId: activeAccount.id, reportName });
     const dataParamValue = useDataParam();
     const { segmentId: paramSegmentId } = dataParamValue || {};
     const location = useLocation();
     const reportDetailData = REPORT_DATA_TYPES[reportName];
     const primaryObject = useSelector((state) => state.subscriber.primaryObjectList);
     const secondaryObject = useSelector((state) => state.subscriber.secondaryObjectList);
     const salesConversionName = useSelector((state) => state.subscriber.salesConversionNameList);
     
     const reportSalesConversion = reportName === REPORT_NAMES.SALES_CONVERSION_BY_CONVERSION || reportName === REPORT_NAMES.SALES_CONVERSION_BY_PROPERTY
     
     const isChangeScope = useRef(false);
     const [scoped, setScoped] = useState(isDraft && draftReport && draftReport.scoped ? draftReport.scoped :
          reportName === 'sessionDurationReport' || reportName === 'conversionCountReport'
          ? Object.keys(REPORT_DATA_TYPES[reportName])[1]
          : Object.keys(REPORT_DATA_TYPES[reportName])[0]
     );

     useEffect(() => {
          if(reportSalesConversion) {
               dispatch(getSalesConversionObject(activeAccount.id));
               dispatch(getSalesConversionName(activeAccount.id));
          }
     }, [activeAccount.id, reportName]) //eslint-disable-line

     const metricSecondaryObject = handleAddMetricSalesConversion(secondaryObject, reportName);
     const metricIndividualSalesConversion = handleAddMetricSalesConvNameIndividual(salesConversionName, reportName);

     // list Metric By Object Sales Conversion Report
     function updateMetrics(metricsSource, filterKey) {
          if (metricsSource && reportSalesConversion) {
               reportDetailData[scoped].metrics = reportDetailData[scoped].metrics.filter(metric => !metric[filterKey]);
          
               metricsSource.forEach(item => {
                    if (!reportDetailData[scoped].metrics.some(metric => metric.key === item.key)) {
                         reportDetailData[scoped].metrics.push(item);
                    }
               });
          }
     }
      
     updateMetrics(metricSecondaryObject, 'object');
     updateMetrics(metricIndividualSalesConversion, 'conversionID');

     const history = useHistory();

     const dateScopes =
          (reportDetailData && (reportDetailData.dateScopes || (reportDetailData[scoped] && reportDetailData[scoped].dateScopes))) || [];
     const dateScopeOptions = dateScopes.map((item) => ({ label: item, value: item }));
     const initDateScope =
          (dataParamValue && dataParamValue.dateScope) ||
          (dateScopes.includes(DATE_SCOPE.CREATION_DATE) ? DATE_SCOPE.CREATION_DATE : DATE_SCOPE.EVENT_ACTIVITY);
     let {
          dimensions,
          properties,
          metrics,
          defaultDimensions = [],
          defaultWeight,
          isDefaultMetrics,
     } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];

     const showTargetAccount = activeAccount.usingTargetAccount;
     if (!showTargetAccount) {
          properties = properties.filter((item) => !['targetAccount', 'targetAccountDomain'].includes(item.key))
     }
     const {
          isConversionName,
          isConversionNameRate,
          isConversionAllReport,
     } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const [conversionNameMetric, setConversionNameMetric] = useState([]);
     const [conversionName, setConversionName] = useState([]);
     const [utmArray, setUtmArray] = useState([]);
     const [eCoItemCategories, setECoItemCategories] = useState([]);
     const user = useSelector((state) => state.theme.user);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const customDimensions = useSelector((state) => state.subscriber.customReport.dimensions);
     const listSegmentReport = useSelector((state) => state.subscriber.listSegmentReport);
     const listSegment = useSelector((state) => state.subscriber.listSegment);
     const scopes = useSelector((state) => state.subscriber.customReport.scopes);
     const dayjsNow = dayjs().tz(activeAccount.timeZone);
     const initListSegmentReportApply = recordProfile ? [] : typeof paramSegmentId !== 'undefined' ? listSegmentReport.filter((el) => el.id === paramSegmentId) : listSegmentReport.filter((item) => item.apply);

     const [listSegmentReportApply, setListSegmentReportApply] = useState(initListSegmentReportApply);
     const [listChartSegmentApply, setListChartSegmentApply] = useState([]);
     const [reportView, setReportView] = useState({});
     const reportViewParam = new URLSearchParams(location.search).get('reportView');
     const showNewNavigation = localStorage.getItem('showNewNavigation') || window.location.search.includes('?show-navigation=1');

     const disableSort = reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW;
     let defaultDateRange = {
          selection: {
               startDate: dayjsNow.subtract(1, 'day'),
               endDate: dayjsNow.subtract(0, 'day'),
          },
     };

     const [defaultConfig, setDefaultConfig] = useState({})

     useEffect(() => {
          if (!dateRangeConfig?.sticky && draftReport && draftReport.dateRangeReports && !dataParamValue) {
               const newDateRange = {
                    ...draftReport.dateRangeReports,
                    selection: {
                         endDate: dayjs(draftReport.dateRangeReports.selection.endDate),
                         startDate: dayjs(draftReport.dateRangeReports.selection.startDate),
                    }
               }
               if (draftReport.dateRangeReports.compare) {
                    newDateRange.compare = {
                         endDate: dayjs(draftReport.dateRangeReports.compare.endDate),
                         startDate: dayjs(draftReport.dateRangeReports.compare.startDate),
                    }
               }
               dispatch(setDateRangeReports(newDateRange));
          } else {
               if (isEmpty(dateRangeReports)) {
                    dispatch(setDateRangeReports(defaultDateRange));
               }
          }
     }, []); //eslint-disable-line

     useUtmArray({
          utmArray,
          setUtmArray,
          dimensions,
          reportName,
          dateRangeReports,
     });

     const accountId = activeAccount.id;
     const customDimensionsTemp = customDimensions.filter((item) => item.status);
     const { newProperties, customProperties } = getPropertiesByCustomDimension({
          dimensions,
          properties,
          customDimensionsTemp,
          reportName,
          scoped,
          scopes,
          showTargetAccount
     });
     // const maxStepForm = useSelector((state) => state.subscriber.maxStepForm);
     const [reloadTable, setReloadTable] = useState(false);

     const [tableData, setTableData] = useState([]);
     const [metricsStepForm, setMetricsStepForm] = useState([]);
     const [filterMetric, setFilterMetric] = useState(isDraft && draftReport && draftReport.metrics ? draftReport.metrics : handleGetOption({ option: metrics, isDefaultMetrics, type: 'defaultMetric' }));
     const [filterDimensions, setFilterDimensions] = useState(
          isDraft && draftReport && draftReport.dimensions ? draftReport.dimensions : handleGetOption({ option: [REPORT_NAMES.BY_CONVERSION,REPORT_NAMES.BY_PROPERTY].includes(reportName) ? [...dimensions, ...newProperties] : dimensions, type: 'defaultDimension', reportName: reportName, defaultArr: defaultDimensions })
     );

     const [maxStepForm, setMaxStepForm] = useState(0);
     const [filterProperties, setFilterProperties] = useState(isDraft && draftReport && draftReport.properties ? draftReport.properties : handleGetOption({ option: properties.filter((_p) => _p.isDefault) }));
     const [sort, setSort] = useState(
          isDraft && draftReport && draftReport.sort ? draftReport.sort :
               (filterMetric[0] && reportName !== REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW ? { fieldName: filterMetric[0].value, isDesc: true } : {})
     );
     const [itemsPerPage, setItemsPerPage] = useState(isDraft && draftReport && draftReport.itemsPerPage ? draftReport.itemsPerPage : 20);
     const [isFilterConversion, setIsFilterConversion] = useState(false);
     const [offSet, setOffSet] = useState(0);
     const [fetchNextPage, setFetchNextPage] = useState(false);
     const [showChart, setShowChart] = useState(false);
     const [changeData, setChangeData] = useState(false);
     //
     const [metricDefault, setMetricDefault] = useState([]);
     const [convRate, setConvRate] = useState([]);
     const [convName, setConvName] = useState([]);
     const [weight, setWeight] = useState(defaultWeight);
     const [isDirect, setIsDirect] = useState(false);
     const [eCommerce, setECommerce] = useState([]);
     const [segmentSticky, setSegmentSticky] = useState(true);
     const [isLoading, setIsLoading] = useState(true);
     const [attributeBeforeReload, setAttributeBeforeReload] = useState({ dimensions: [], properties: [], metrics: [], scoped: '' });
     const [isRemoveCustomPropertyHaveFilter, setIsRemoveCustomPropertyHaveFilter] = useState(false);
     const { enableFullAnalytics, isRevealedCompanies, behavioralAnalytics } = activeAccount;
     const showData = localStorage.getItem('llShowData') || window.location.search.includes('show-data=1') || behavioralAnalytics || enableFullAnalytics;
     const enableRevealedCompanies =
          activeAccount.enableRevealedCompanies ||
          isRevealedCompanies ||
          localStorage.getItem('llRevealedCompany') === '1' ||
          window.location.search.includes('revealed-company=1');
     const convertToVariableSelect = () => {
          const newArr = [];
          if (REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT === reportName || REPORT_NAMES.ECOMMERCE_ITEMS === reportName) {
               [...dimensions, ...utmArray, ...eCoItemCategories].forEach((e) => {
                    const filterDimension = filterDimensions.find((item) => item.value === e.key);
                    if (filterDimension) {
                         newArr.push({ value: e.key, label: e.label });
                    }
               });
          } else {
               dimensions.forEach((e) => {
                    if (reportName !== REPORT_NAMES.BY_LANDING_PAGE_REPORT) {
                         newArr.push({ value: e.key, label: e.label });
                    } else if (e.key !== 'pathName') {
                         newArr.push({ value: e.key, label: e.label });
                    }
               });
          }
          if (![REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY].includes(reportName)) {
               newProperties.forEach((e) => {
                    // const findCustom = customProperties.find((item) => item.properties === e.key && item.isCustom === false);
                    if (customProperties.find((item) => item.properties === e.key)) {
                         // const findCustomDefault = TYPE_PROPERTIES_CUSTOM_FILTER_NEED_REMOVE.find(
                         //      (el) => findCustom && el.value === findCustom.properties
                         // );
                         // if (!findCustomDefault) {
                         newArr.push({ value: e.key, label: `${e.label}` });
                         // }
                    } else {
                         newArr.push({ value: e.key, label: e.label });
                    }
               });
          }
          filterMetric.forEach((e) => {
               if (
                    reportName !== REPORT_NAMES.EVENT_DETAILS &&
                    reportName !== REPORT_NAMES.PAGE_VIEW_DETAILS &&
                    reportName !== REPORT_NAMES.FORM_SUBMISSION_EVENTS
               ) {
                    newArr.push({ value: e.value, label: e.label });
               }
          });
          FILTER_DATA_TYPES[reportName].forEach((e) => {
               if (e.type) {
                    newArr.push({ value: e.type, label: e.label });
               }
          });
          if (dataParamValue && Array.isArray(dataParamValue.filters)) {
               if (reportName === REPORT_NAMES.SESSION_DETAILS) {
                    let conversionSource = REPORT_DATA_TYPES[REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT][SCOPED.CONVERSION];
                    const array = [];
                    utmArray.forEach((e) => {
                         const dimension = conversionSource.dimensions.find((item) => item.label === e.key);
                         if (!dimension) {
                              array.push({ value: e.key, label: e.label });
                         }
                    });
                    const findLinkUtm = dataParamValue.filters.find((el) => array.some((e) => e.value === el.type));
                    if (findLinkUtm) {
                         newArr.push({ value: findLinkUtm.type, label: findLinkUtm.type });
                    }
               }
               if ([REPORT_NAMES.SESSION_DETAILS, REPORT_NAMES.USER_DETAILS, REPORT_NAMES.PERSON_DETAILS, REPORT_NAMES.COMPANY_DETAILS].includes(reportName)) {
                    dataParamValue.filters.forEach((el) => {
                         if ([REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY].includes(el.reportName) && !newArr.some((e) => e.value === el.type)) {
                              let {
                                   dimensions: paramDimensions,
                              } = REPORT_DATA_TYPES[el.reportName][el.scoped];
                              const paramOption = paramDimensions.find((e) => e.key === el.type);
                              newArr.push({ value: el.type, label: paramOption?.label });
                         }
                    })
               }
          }

          return newArr;
     };
     // const filterOptionDefaults = convertToVariableSelect();
     const [filterOptions, setFilterOptions] = useState(convertToVariableSelect());

     useEffect(() => {
          setFilterOptions(convertToVariableSelect());
     }, [filterProperties, filterMetric, filterDimensions]); //eslint-disable-line

     useEffect(() => {
          if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
               callTokenApi(API_GET_ECO_ITEM_CATEGORIES, 'POST', { accountId }).then((response) => {
                    if (response && response.status === 200) {
                         const itemCategories = response.data.itemCategories;
                         setECoItemCategories(itemCategories);
                    } else {
                         setECoItemCategories([]);
                    }
               });
          }
     }, []); //eslint-disable-line

     useEffect(() => {
          if (listSegmentReport && !recordProfile) {
               setListSegmentReportApply(listSegmentReport.filter((item) => item.apply).sort((a, b) => {
                    if (a.isCustom !== b.isCustom) {
                         return a.isCustom ? 1 : -1;
                    }
                    return new Date(a.orderBy) - new Date(b.orderBy)
               }));
          }
     }, [listSegmentReport]); //eslint-disable-line

     const [firstChangeData, setFirstChangeData] = useState(false); //eslint-disable-line
     const [filterEvent, setFilterEvent] = useState(() => {
          let filters = [];
          if (dataParamValue && Array.isArray(dataParamValue.filters)) {
               dataParamValue.filters.forEach((el) => {
                    const foundIndex = filterOptions.findIndex((filterOption) => filterOption.value === el.type);
                    const foundUtm = el.utmArray && el.utmArray.findIndex((utm) => utm.key === el.type);

                    if (foundIndex > -1 || foundUtm >= 0) {
                         filters.push(el);
                    }

               });

               if (filters.length > 0) {
                    setUtmArray([
                         ...filters.map((item) => {
                              return { key: item.type, label: item.type };
                         }),
                    ]);
               }
          } else {
               if (isDraft && draftReport && draftReport.filterEvent) {
                    filters = draftReport.filterEvent
               } else {
                    filters = [{ type: '', value: '', id: '', operator: 'ct', optionsOperator: VARIABLE_OPERATORS_REPORT }];
               }
          }
          return filters;
     });

     // useEffect(() => {
     //      if (metricsStepForm.length > 0) {
     //           setFilterOptions(() => [
     //                ...filterOptionDefaults,
     //                ...metricsStepForm.map((item) => {
     //                     return { value: item.key, label: item.label };
     //                }),
     //           ]);
     //      }
     // }, [metricsStepForm]); //eslint-disable-line
     useEffect(() => {
          setFilterOptions(convertToVariableSelect());
     }, [filterProperties, scoped]); //eslint-disable-line
     // useEffect(() => {
     //      if(REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT === reportName){
     //           setFilterOptions(convertToVariableSelect());
     //      }
     // }, [filterDimensions]); //eslint-disable-line
     useEffect(() => {
          if (recordProfile) {
               setFilterEvent(filterRecord);
               setItemsPerPage(10);
          }
     }, [recordProfile]); // eslint-disable-line react-hooks/exhaustive-deps

     const [reportData, setReportData] = useState({
          overallRowCount: 0,
          rows: [],
          event: [],
          totalsRow: [],
     });
     const [reportCompare, setReportCompare] = useState({ rows: undefined, totalsRow: undefined });
     const [filterEventReport, setFilterEventReport] = useState(false);
     const [triggerClear, setTriggerClear] = useState(false);
     const [viewLoading, setViewLoading] = useState(false);
     const [dateRangeConfig, setDateRangeConfig] = useState(isDraft && draftReport && draftReport.dateRangeConfig ? draftReport.dateRangeConfig : {
          comparePeriod: false,
          periodType: PERIOD_TYPES.PREVIOUS_PERIOD,
          datePreset: null,
          dateScope: initDateScope,
          sticky: true
     });

     const [tempReport, setTempReport] = useState({ temp: ``, tempCompare: `` });
     const [tempScopedReport, setTempScopedReport] = useState({ tempScoped: ``, tempScopedCompare: `` });
     const [dimensionEvents, setDimensionEvents] = useState([]);
     const [isWebConversion, setIsWebConversion] = useState(false);
     useEffect(() => {
          if (!(isDraft && draftReport && draftReport.dateRangeConfig && draftReport.dateRangeConfig.dateScope)) {
               setDateRangeConfig((prev) => ({ ...prev, dateScope: initDateScope }));
          }
     }, [initDateScope]); // eslint-disable-line react-hooks/exhaustive-deps

     if (dataParamValue && dataParamValue.dateRange) {
          defaultDateRange.selection = {
               startDate: dayjs(dataParamValue.dateRange.startDate),
               endDate: dayjs(dataParamValue.dateRange.endDate),
          };
     }
     if (dataParamValue && dataParamValue.isDateRange) {
          defaultDateRange.selection = {
               startDate: dayjsNow.subtract(14, 'day'),
               endDate: dayjsNow.subtract(0, 'day'),
          };
     }
     // const [dateRange, setDateRange] = useState(defaultDateRange);

     const conversionNameLoading = useFirstFetchReportData({
          isConversionNameRate,
          isConversionName,
          isConversionAllReport,
          metrics,
          reportName,
          filterMetric,
          setConversionNameMetric,
          setConversionName,
          setSort,
          setSegmentSticky,
          dataParamValue,
          recordProfile,
          dateRangeConfig,
          defaultDateRange,
          setDateRangeConfig,
          dateScopes,
          setListSegmentReportApply,
          setListChartSegmentApply,
          isDraft,
          draftReport,
          setDefaultConfig,
          reportViewParam,
          reportType,
     });

     const handleSetFilterDimensions = (newDimensions) => {
          // if (newDimensions.length === 0) {
          //      toast.error(`Can't remove all dimensions`);
          //      return;
          // }

          // if (newDimensions.length && newDimensions[0].value === 'formHostNamePathName') {
          //      toast.error(`Can't remove FormID dimensions`);
          //      return;
          // }
          setFilterDimensions(newDimensions);
     };

     const fetchDimensions = () => {
          dispatch(fetchDimensionsRequest(accountId));
     };

     const fetchScopes = () => {
          dispatch(fetchScopesRequest(accountId));
     };
     useEffect(() => {
          if (customDimensions.length === 0) {
               fetchDimensions();
          }
          if (scopes.length === 0) {
               fetchScopes();
          }
     }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

     // const filterMetricSelection = (arr1, arr2) => {
     //      const result = arr1.filter((item1) => {
     //           const valueStartsWithStep = item1.value.startsWith('step');
     //           const existsInObject2 = arr2.some((item2) => item1.value === item2.value);
     //           return !(valueStartsWithStep && !existsInObject2);
     //      });
     //      return result;
     // };

     // const mergeArraysDynamic = (arr1, arr2) => {
     //      //eslint-disable-line
     //      // const existingSteps = new Set(
     //      //      arr1.filter((item) => item.value.startsWith('step')).map((item) => item.label.split(' ')[0] + item.label.split(' ')[1])
     //      // );

     //      // const newSteps = arr2.filter(
     //      //      (item) => item.value.startsWith('step') && !existingSteps.has(item.label.split(' ')[0] + item.label.split(' ')[1])
     //      // );

     //      const mergedArray = [...arr1];

     //      const stepArr2 = new Set(
     //           arr2.filter((item) => item.value.startsWith('step')).map((item) => item.label.split(' ')[0] + item.label.split(' ')[1])
     //      );

     //      const result = mergedArray.filter(
     //           (item) => !item.value.startsWith('step') || stepArr2.has(item.label.split(' ')[0] + item.label.split(' ')[1])
     //      );

     //      return result;
     // };

     // useEffect(() => {
     // if (reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_ACTIVITY) {
     //      setFilterMetric((prev) => prev.filter(item => item.value !== 'companyCount'));
     // }

     // if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT && firstChangeData) {
     //      setFilterMetric((prev) => mergeArraysDynamic(prev, handleGetOption({ option: [...metricsStepForm] })));
     // }
     // }, [tableData]); //eslint-disable-line

     useEffect(() => {
          if (reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION) {
               const newProperties = properties.filter((_item) => !['userSourceFirst', 'userSourceLast'].includes(_item.key));
               setFilterProperties(handleGetOption({ option: newProperties }));
          }
     }, []); //eslint-disable-line

     useEffect(() => {
          setIsFilterConversion(false);
     }, [scoped]);

     useEffect(() => {
          if (filterProperties.length > 0 && filterOptions.length > 0) {
               let newOptions = [...filterEvent];
               let change = false;
               filterEvent.forEach((item) => {
                    const checkSelectCustomPropeties = filterProperties.find((el) => el.value === item.type); // select custom properties
                    const checkFilterCustomPropeties = customProperties.find((el) => el.properties === item.type); // select filter custom propeties
                    if (!checkSelectCustomPropeties && checkFilterCustomPropeties) {
                         newOptions = newOptions.filter((el) => el.type !== item.type);
                         change = true;
                    }
               });
               if (change) {
                    if (newOptions.length === 0) {
                         newOptions.push({
                              type: '',
                              value: '',
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_REPORT,
                         });
                    }
                    setFilterEvent(newOptions);
                    setIsRemoveCustomPropertyHaveFilter(change);
               }
          }
     }, [filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     const newDateRange = {
          startDate: dateRangeReports.selection && dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
          endDate: dateRangeReports.selection && dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
     };

     //report view

     const [listView, setListView] = useState([]);
     const [listViewByScoped, setListViewByScoped] = useState([]);
     const [viewDefault, setViewDefault] = useState({});
     const [isMapView, setIsMapView] = useState(true);
     const [isHandleParams, setIsHandleParams] = useState(true);
     const [isApplyMetric, setIsApplyMetric] = useState(true);
     const [isLinkReport, setIsLinkReport] = useState(false);
     const [listSegmentApplyOff, setListSegmentApplyOff] = useState([]);
     const [isSaveReport, setIsSaveReport] = useState(false);


     // fetch list saved views
     const fetchSavedViews = () => {
          setViewLoading(true);

          const query = `?accountId=${activeAccount.accountId}&userId=${user.id}&reportName=${reportName}`;

          callTokenApi(`${CLIENT_GET_SAVED_VIEWS}/${query}`, 'GET').then((response) => {
               if (response.status === 200) {
                    const reportViews = response.data.reportViews;
                    setViewLoading(false);

                    const isFilter = !dataParamValue;
                    const findViewDefault = (reportViews.find(_item => _item.isDefault) || reportViews.find(_item => _item.isSystem));
                    if (findViewDefault) {
                         if (isDraft && draftReport) {
                              if (draftReport.viewId) {
                                   const newFindViewDefault = reportViews.find(_item => _item.id === draftReport.viewId);
                                   if (newFindViewDefault && (draftReport.metrics ? JSON.stringify(draftReport.metrics) === JSON.stringify(newFindViewDefault.content.metrics) : true)
                                        && (draftReport.properties ? JSON.stringify(draftReport.properties) === JSON.stringify(newFindViewDefault.content.properties) : true)
                                        && (draftReport.dimensions ? JSON.stringify(draftReport.dimensions) === JSON.stringify(newFindViewDefault.content.dimensions) : true)
                                        && (draftReport.filter ? JSON.stringify(draftReport.filter) === JSON.stringify(newFindViewDefault.content.filter) : true)

                                   ) {
                                        setViewDefault(newFindViewDefault);
                                   } else {
                                        setIsMapView(false)
                                   }
                              } else {
                                   setIsMapView(false)
                              }
                         }
                         if (!isDraft && !draftReport) {
                              setViewDefault(findViewDefault);
                              setFilterViewDefault(findViewDefault, isFilter);
                         }

                         if (findViewDefault.isSystem) {
                              const newReportViews = reportViews.map((_view) => {
                                   if (_view.id === findViewDefault.id) {
                                        _view.isDefault = true;
                                   }
                                   return _view;
                              });
                              setListView(newReportViews);
                         } else {
                              setListView(reportViews);
                         }
                    }

                    if (dataParamValue) {
                         const contentFilter = dataParamValue.filters.length
                              ? dataParamValue.filters.map((_param) => ({
                                   type: _param.type,
                                   value: _param.value,
                                   id: '',
                                   operator: _param.operator,
                                   optionsOperator: _param.optionsOperator,
                              }))
                              : [];

                         for (const _item of reportViews) {
                              const itemFilter = [];

                              for (const _filter of _item.content.filter) {
                                   itemFilter.push({
                                        type: _filter.type,
                                        value: _filter.value,
                                        id: '',
                                        operator: _filter.operator,
                                        optionsOperator: _filter.optionsOperator,
                                   });
                              }

                              const content = JSON.stringify(contentFilter);
                              const itemContent = JSON.stringify(itemFilter);

                              if (content === itemContent) {
                                   setFilterViewDefault(_item, isFilter);
                                   setIsMapView(true);

                                   break;
                              } else {
                                   setIsLinkReport(true)
                                   setIsMapView(false);
                              }
                         }
                    }
               }
          });
     };

     //get list view by scoped
     useEffect(() => {
          if (listView.length > 0) {
               const newListViewByScoped = listView.filter((_view) => _view.scoped === scoped);
               setListViewByScoped(newListViewByScoped);
          }
     }, [listView]); // eslint-disable-line

     //get list view by scoped
     useEffect(() => {
          if (listView.length > 0) {
               const newListViewByScoped = listView.filter((_view) => _view.scoped === scoped);
               setListViewByScoped(newListViewByScoped);

               const findViewDefault = newListViewByScoped.find(_item => _item.isDefault) || newListViewByScoped.find(_item => _item.isSystem);
               if (findViewDefault) {
                    setViewDefault(findViewDefault);
                    setFilterViewDefault(findViewDefault);
               }
          }

     }, [scoped]); // eslint-disable-line

     const getReportValue = (scoped) => {
          let { dimensions, properties, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];

          const { newProperties } = getPropertiesByCustomDimension({
               dimensions,
               properties,
               customDimensionsTemp,
               reportName,
               scoped,
               scopes,
               showTargetAccount
          });

          if (!showTargetAccount) {
               properties = properties.filter((item) => !['targetAccount', 'targetAccountDomain'].includes(item.key))
          }

          if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
               dimensions = [...dimensions, ...eCoItemCategories];
          }

          dimensions = handleGetOption({ option: dimensions, reportName });
          properties = handleGetOption({ option: newProperties, type: 'option', filter: [] });
          metrics = handleGetOption({ option: [...metrics, ...conversionNameMetric, ...metricsStepForm] });

          return {
               dimensions,
               properties,
               metrics,
          };
     };

     const sortAndFilterArray = (referenceArray, targetArray) => {
          targetArray.sort((a, b) => {
               const indexA = referenceArray.indexOf(a.value);
               const indexB = referenceArray.indexOf(b.value);

               if (indexA === -1 && indexB === -1) return 0;
               if (indexA === -1) return 1;
               if (indexB === -1) return -1;

               return indexA - indexB;
          });
          return targetArray.filter((item) => referenceArray.includes(item.value));
     };

     //set (dimensions, properties, metric, filter) with view
     const setFilterViewDefault = (viewDefault, isFilter = true, isApplyMetric = true, isFetchReport = true) => {
          if (viewDefault && Object.keys(viewDefault).length > 0) {
               setViewDefault(viewDefault);
               const content = viewDefault.content;
               const reportValue = getReportValue(viewDefault.scoped);
               setFilterDimensions(sortAndFilterArray(content.dimensions, reportValue.dimensions));
               setFilterProperties(sortAndFilterArray(content.properties, reportValue.properties));
               if (isApplyMetric) {
                    setFilterMetric(sortAndFilterArray(content.metrics, reportValue.metrics));
               }

               if (content.weight) {
                    setIsDirect(content.weight.noneDirect);
                    setWeight(content.weight);
               }

               if (isFilter && !recordProfile) {
                    if (content.filter && content.filter.length) {
                         content.filter.forEach((item, index) => {
                              delete item.scoped
                              delete item.tempScopedReport

                              if (dataParamValue && dataParamValue.filters && Array.isArray(dataParamValue.filters)) {
                                   const findFilter = dataParamValue.filters.find((filter) => filter.type === item.type);
                                   if (findFilter) {
                                        item.scoped = findFilter.scoped;
                                        item.tempScopedReport = findFilter.tempScopedReport;
                                   }
                              }
                         })
                    }
                    setFilterEvent(content.filter);
               }

               if (isFetchReport) {
                    setFilterEventReport(!filterEventReport);
               }
               setScoped(viewDefault.scoped);
               setIsMapView(true);
          }
     };

     useEffect(() => {
          if (!customDimensions) return;
          const listCustomPropertyEvent = customDimensions.filter((item) =>
               item.availableScopes.some((scope) => scope.isWebConversion && filterProperties.some((option) => option.label === item.name))
          );
          setDimensionEvents(listCustomPropertyEvent);
          if (listCustomPropertyEvent && listCustomPropertyEvent.length > 0) {
               setIsWebConversion(true);
          } else {
               setIsWebConversion(false);
          }
     }, [customDimensions, filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     //handle with param reportView
     const handleParamsReportView = (reportView) => {
          if (Object.keys(reportView).length > 0) {
               const viewContent = reportView.content;

               const dateRangeConfig = viewContent.dateRangeConfig
               let newDateRange
               let newConfig

               const isSaveReport = reportView.type === VIEW_TYPE.REPORT
               setIsSaveReport(isSaveReport);

               if (dateRangeConfig && isSaveReport) {
                    const { type, dateRanges: dateRangesStatic, dateScope, rolling, sticky } = dateRangeConfig;

                    switch (type) {
                         case SAVE_REPORT_DATE_OPTION.NO_RANGE:
                              const dateRangeSession = localStorage.getItem(SESSION_STORAGE_REPORT_DATE_RANGE) ? JSON.parse(localStorage.getItem(SESSION_STORAGE_REPORT_DATE_RANGE)) : null;
                              if (dateRangeSession && !isEmpty(dateRangeSession)) {
                                   newDateRange = {
                                        ...(dateRangeSession.compare && {
                                             compare: {
                                                  startDate: dayjs(dateRangeSession.compare.startDate),
                                                  endDate: dateRangeSession.compare.endDate ? dayjs(dateRangeSession.compare.endDate) : dayjs(dateRangeSession.compare.startDate),
                                             },
                                        }),
                                        selection: {
                                             startDate: dayjs(dateRangeSession.selection.startDate),
                                             endDate: dateRangeSession.selection.endDate ? dayjs(dateRangeSession.selection.endDate) : dayjs(dateRangeSession.selection.startDate),
                                        },
                                   };
                                   newConfig = { comparePeriod: dateRangeSession.compare, datePreset: dateRangeSession.datePreset, periodType: dateRangeSession.periodType, sticky }
                                   dispatch(setDateRangeReports(newDateRange));
                                   setDateRangeConfig((prev) => ({ ...prev, ...newConfig }));
                              }
                              break;
                         case SAVE_REPORT_DATE_OPTION.STATIC_RANGE:
                              if (dateRangesStatic) {
                                   newDateRange = {
                                        ...(dateRangesStatic.compare && {
                                             compare: {
                                                  startDate: dayjs(dateRangesStatic.compare.startDate),
                                                  endDate: dateRangesStatic.compare.endDate ? dayjs(dateRangesStatic.compare.endDate) : dayjs(dateRangesStatic.compare.startDate),
                                             },
                                        }),
                                        selection: {
                                             startDate: dayjs(dateRangesStatic.selection.startDate),
                                             endDate: dateRangesStatic.selection.endDate ? dayjs(dateRangesStatic.selection.endDate) : dayjs(dateRangesStatic.selection.startDate),
                                        },
                                   };
                                   dispatch(setDateRangeReports(newDateRange));
                                   newConfig = { dateScope: dateScope, comparePeriod: dateRangesStatic.compare, datePreset: dateRangesStatic.datePreset, periodType: dateRangesStatic.periodType, sticky }
                                   setDateRangeConfig((prev) => ({ ...prev, ...newConfig }));

                                   localStorage.setItem(SESSION_STORAGE_REPORT_DATE_RANGE, JSON.stringify({ ...newConfig, ...newDateRange }));
                              }
                              break;
                         case SAVE_REPORT_DATE_OPTION.ROLLING_RANGE:
                              if (rolling) {
                                   const { type: rollingType, numberPrevious, isComparison, periodType, customDateCompare } = rolling;
                                   const lastWeek = dayjsNow.subtract(1, 'week');
                                   const lastMonth = dayjsNow.subtract(1, 'month');
                                   const lastDay = dayjsNow['$D'] === 1 ? dayjsNow.subtract(1, 'day') : dayjsNow;

                                   switch (rollingType) {
                                        case ROLLING_DATE_RANGE.previous_week_mon_sun:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(lastWeek.startOf('week')['$d']).add(1, 'day'),
                                                       endDate: dayjs(lastWeek.endOf('week')['$d']).add(1, 'day')
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.previous_week_sun_sat:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(lastWeek.startOf('week')['$d']),
                                                       endDate: dayjs(lastWeek.endOf('week')['$d'])
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.previous_month:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(lastMonth.startOf('month')['$d']),
                                                       endDate: dayjs(lastMonth.endOf('month')['$d'])
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.month_to_date_yesterday:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(lastDay.startOf('month')['$d']),
                                                       endDate: dayjs(dayjsNow['$d']).subtract(1, 'day'),
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.year_to_date_yesterday:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(lastDay.startOf('year')['$d']),
                                                       endDate: dayjs(dayjsNow['$d']).subtract(1, 'day'),
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.previous_x_days:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(dayjsNow['$d']).subtract(numberPrevious, 'day'),
                                                       endDate: dayjs(dayjsNow['$d']).subtract(1, 'day'),
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.previous_x_weeks:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(dayjsNow['$d']).subtract(numberPrevious, 'week'),
                                                       endDate: dayjs(dayjsNow['$d']).subtract(1, 'day'),
                                                  }
                                             };
                                             break;
                                        case ROLLING_DATE_RANGE.quarter_to_date:
                                             newDateRange = {
                                                  selection: {
                                                       startDate: dayjs(lastDay.startOf('quarter')['$d']),
                                                       endDate: dayjs(dayjsNow['$d']).subtract(1, 'day'),
                                                  }
                                             };
                                             break;

                                        default:
                                             break;
                                   }

                                   if (isComparison) {
                                        const startDateSelection = dayjs(newDateRange.selection.startDate);
                                        const endDateSelection = dayjs(newDateRange.selection.endDate);
                                        const selectionDateSubtract = Math.floor((endDateSelection - startDateSelection) / (1000 * 3600 * 24));
                                        switch (periodType) {
                                             case PERIOD_TYPES.PREVIOUS_PERIOD:
                                                  newDateRange.compare = {
                                                       startDate: startDateSelection.subtract(selectionDateSubtract, 'day'),
                                                       endDate: startDateSelection.subtract(1, 'day')
                                                  }
                                                  break;

                                             case PERIOD_TYPES.PREVIOUS_YEAR:
                                                  newDateRange.compare = {
                                                       startDate: startDateSelection.subtract(1, 'year'),
                                                       endDate: endDateSelection.subtract(1, 'year')
                                                  }
                                                  break;
                                             case PERIOD_TYPES.CUSTOM:
                                                  if (customDateCompare) {
                                                       newDateRange.compare = {
                                                            startDate: dayjs(customDateCompare.selection.startDate),
                                                            endDate: customDateCompare.selection.endDate ? dayjs(customDateCompare.selection.endDate) : dayjs(customDateCompare.selection.startDate),
                                                       }
                                                  }

                                                  break;

                                             case PERIOD_TYPES_NEW.PREVIOUS_MONTH:
                                                  newDateRange.compare = {
                                                       startDate: startDateSelection.subtract(1, 'month'),
                                                       endDate: endDateSelection.subtract(1, 'month')
                                                  }

                                                  break;

                                             case PERIOD_TYPES_NEW.PREVIOUS_QUARTER:
                                                  const startQuarter = startDateSelection.subtract(1, 'quarter')
                                                  const endQuarter = startQuarter.add(selectionDateSubtract, 'day')
                                                  newDateRange.compare = {
                                                       startDate: startQuarter,
                                                       endDate: startQuarter.quarter() === endQuarter.quarter() ? endQuarter : startQuarter.endOf('quarter')
                                                  }

                                                  break;

                                             default:
                                                  break;
                                        }
                                   }
                                   dispatch(setDateRangeReports(newDateRange));

                                   newConfig = { dateScope: dateScope, comparePeriod: !!newDateRange.compare, periodType, sticky }
                                   setDateRangeConfig((prev) => ({ ...prev, ...newConfig }));
                              }
                              break;

                         default:
                              break;
                    }

                    localStorage.setItem(SESSION_STORAGE_REPORT_DATE_RANGE, JSON.stringify({ ...newConfig, ...newDateRange }));
                    if (sticky) {
                         const dateRangeConfigSave = { ...dateRangeConfig, ...newConfig, ...newDateRange };
                         const data = {
                              accountId,
                              reportName,
                              dateRangeConfig: dateRangeConfigSave,
                              userId: user.id,
                              isSaveReport,
                              currentSticky: defaultConfig.dateRangeSticky

                         };
                         callTokenApi(`${API_CLIENT_DEFAULT_REPORT_DATE_RANGE_CONFIGS}`, 'PUT', data)
                    }
               }

               if (isSaveReport) {
                    const viewId = reportView.viewId;
                    const segments = viewContent.segment ?? [];

                    if (listSegmentReport.length > 0) {
                         const newSegments = listSegmentReport.map((item) => ({ ...item, apply: segments.includes(item.id) }));

                         const listSegmentApplyOff = listSegmentReport
                              .filter((segment) => {
                                   const findNewSegment = newSegments.find((_item) => _item.id === segment.id && !_item.apply);
                                   return segment.apply && findNewSegment;
                              })
                              .map((segment) => {
                                   return {
                                        id: segment.id,
                                        segmentReportId: segment.segmentReportId,
                                        apply: false,
                                        applyChart: false,
                                        type: 'update',
                                   };
                              });

                         setListSegmentApplyOff(listSegmentApplyOff);

                         dispatch(setListSegmentReport(newSegments));
                         setSort(
                              handleGetSortSegment({
                                   listSegmentReportApply: listSegmentReport
                                        .filter((item) => segments.includes(item.id))
                                        .filter((item) => item.queryStatus === BQ_JOB_STATUS.DONE),
                                   filterMetric,
                              })
                         );

                         setListSegmentReportApply(
                              listSegmentReport
                                   .filter((item) => segments.includes(item.id))
                                   .sort((a, b) => {
                                        if (a.isCustom !== b.isCustom) {
                                             return a.isCustom ? 1 : -1;
                                        }
                                        return new Date(a.orderBy) - new Date(b.orderBy);
                                   })
                         );

                         setSegmentSticky(viewContent.segmentSticky);
                    }

                    if (viewId) {
                         setScoped(reportView.scoped);
                         const findView = listViewByScoped.find((view) => view.id === viewId);
                         if (findView) {
                              setFilterViewDefault(findView);
                              setIsMapView(true);
                         }
                         return;
                    }
               }

               const reportValue = getReportValue(reportView.scoped);

               const dimensionValue = sortAndFilterArray(viewContent.dimensions, reportValue.dimensions);
               const propertyValue = sortAndFilterArray(viewContent.properties, reportValue.properties);
               const metricValue = sortAndFilterArray(viewContent.metrics, reportValue.metrics);
               const payload = {
                    filter: viewContent.filter,
                    metrics: metricValue.length ? metricValue.map((_item) => _item.value) : [],
                    dimensions: dimensionValue.length ? dimensionValue.map((_item) => _item.value) : [],
                    properties: propertyValue.length ? propertyValue.map((_item) => _item.value) : [],
                    ...(viewContent.weight && { weight: viewContent.weight }),
               };

               const content = JSON.stringify(payload);

               const isViewMatched = listViewByScoped.some((view) => {
                    const newContent = view.content;
                    const itemContent = JSON.stringify(newContent);
                    if (content === itemContent) {
                         setFilterViewDefault(view);
                         return true;
                    }
                    return false;
               });

               if (isViewMatched) {
                    setIsMapView(true);
               } else {
                    setScoped(reportView.scoped);
                    if (!recordProfile) {
                         setFilterEvent(viewContent.filter);
                    }
                    if (viewContent.weight) {
                         setIsDirect(viewContent.weight.noneDirect);
                         setWeight(viewContent.weight);
                    }
                    if (!(isDraft && draftReport && draftReport.dimensions)) {
                         setFilterDimensions(reportValue.dimensions.filter((dim) => viewContent.dimensions.includes(dim.value)));
                    }
                    if (!(isDraft && draftReport && draftReport.properties)) {
                         setFilterProperties(reportValue.properties.filter((prop) => viewContent.properties.includes(prop.value)));
                    }
                    if (!(isDraft && draftReport && draftReport.metrics)) {
                         setFilterMetric(reportValue.metrics.filter((metric) => viewContent.metrics.includes(metric.value)));
                    }
                    setIsMapView(false);
               }
          }
     };

     //fetch view default
     const fetchReportViewById = () => {
          const parseParams = decodeObj(reportViewParam);
          const idViewReport = parseParams.id;
          const query = `?id=${idViewReport}`;

          callTokenApi(`${CLIENT_GET_SAVED_REPORT_BY_ID}/${query}`, 'GET').then((response) => {
               if (response.status === 200) {
                    const reportView = response.data.reportViews;
                    if (reportView) {
                         if ((reportView.isAvailable && accountId !== reportView.accountId) || (!reportView.isAvailable && reportView.userId !== user.id)) {
                              history.push("/");
                         }
                         setReportView(reportView);
                         handleParamsReportView(reportView);
                    }
               }
          });
     };

     //handle find view when change element(dimensions, properties, metric, filter)
     const handleFindView = (type, values, isFetchReport = true) => {
          let dimensions = filterDimensions;
          let filter = filterEvent;
          let metrics = filterMetric;
          let properties = filterProperties;
          let weightData = {
               ...weight,
               noneDirect: isDirect,
          };

          if (type === REPORT_TAB_TYPES.FILTER) {
               filter = values;
          }

          if (type === REPORT_TAB_TYPES.DIMENSIONS) {
               if (!values.length) {
                    dimensions = [values];
               } else {
                    dimensions = values;
               }
          }

          if (type === REPORT_TAB_TYPES.METRICS) {
               metrics = values;
          }

          if (type === REPORT_TAB_TYPES.PROPERTIES) {
               properties = values;
          }

          if (type === REPORT_TAB_TYPES.MODEL) {
               weightData = {
                    ...values,
               };
          }

          const payload = {
               filter: filter,
               metrics: metrics.length ? metrics.map((_item) => _item.value) : [],
               dimensions: dimensions.length ? dimensions.map((_item) => _item.value) : [],
               properties: properties.length ? properties.map((_item) => _item.value) : [],
               ...(weight && { weight: weightData }),
          };

          const content = JSON.stringify(payload);
          for (const _item of listViewByScoped) {
               const itemContent = JSON.stringify(_item.content);
               if (content === itemContent) {
                    setFilterViewDefault(_item, true, true, isFetchReport);
                    break;
               } else {
                    setIsMapView(false);
               }
          }
     };

     useEffect(() => {
          if (!recordProfile) {
               if (Object.keys(reportView).length === 0 && reportViewParam) {
                    fetchReportViewById();
               }

               if (!listView.length) {
                    fetchSavedViews();
               }
          }
     }, []); // eslint-disable-line

     //update custom dimensions, properties, metric
     useEffect(() => {
          if (!recordProfile && listViewByScoped.length > 0) {
               if (reportViewParam) {
                    if (Object.keys(reportView).length && isHandleParams) {
                         handleParamsReportView(reportView);
                    }
               } else {
                    if (Object.keys(viewDefault).length > 0 && (isMapView || isLinkReport)) {
                         const content = viewDefault.content;
                         const reportValue = getReportValue(viewDefault.scoped);
                         if (!(isDraft && draftReport && draftReport.dimensions)) {
                              setFilterDimensions(sortAndFilterArray(content.dimensions, reportValue.dimensions));
                         }
                         if (!(isDraft && draftReport && draftReport.properties)) {
                              setFilterProperties(sortAndFilterArray(content.properties, reportValue.properties));
                         }
                         if (isApplyMetric && !(isDraft && draftReport && draftReport.metrics)) {
                              setFilterMetric(sortAndFilterArray(content.metrics, reportValue.metrics));
                         }
                    }
               }
          }
     }, [eCoItemCategories, scopes, metricsStepForm, listViewByScoped, reportView, conversionNameMetric]); // eslint-disable-line

     const contextValue = {
          reportName,
          filterMetric,
          setFilterMetric,
          handleGetOption,
          filterDimensions,
          setFilterDimensions: handleSetFilterDimensions,
          filterProperties,
          setFilterProperties,
          filterEvent,
          setFilterEvent,
          setReportData,
          reportData,
          setFilterEventReport,
          filterEventReport,
          isLoading,
          setIsLoading,
          dateRangeConfig,
          setDateRangeConfig,
          filterOptions,
          tableData,
          setTableData,
          sort,
          setSort,
          itemsPerPage,
          setItemsPerPage,
          offSet,
          setOffSet,
          scoped,
          setScoped,
          reportType,
          showChart,
          setShowChart,
          conversionNameMetric,
          conversionName,
          dateScopeOptions,
          isFilterConversion,
          setIsFilterConversion,
          reportCompare,
          setReportCompare,
          fetchNextPage,
          setFetchNextPage,
          showData,
          enableFullAnalytics,
          enableRevealedCompanies,
          changeData,
          setChangeData,
          recordProfile,
          newProperties,
          customProperties,
          metricsStepForm,
          setMetricsStepForm,
          metricDefault,
          setMetricDefault,
          convRate,
          setConvRate,
          convName,
          setConvName,
          eCommerce,
          setECommerce,
          setFirstChangeData,
          isRemoveCustomPropertyHaveFilter,
          setIsRemoveCustomPropertyHaveFilter,
          dataParamValue,
          isChangeScope,
          newDateRange,
          weight,
          setWeight,
          utmArray,
          setUtmArray,
          maxStepForm,
          setMaxStepForm,
          triggerClear,
          setTriggerClear,
          eCoItemCategories,
          isDirect,
          setIsDirect,
          attributeBeforeReload,
          setAttributeBeforeReload,
          listSegment,
          listSegmentReport,
          listSegmentReportApply,
          setListSegmentReportApply,
          listView,
          setListView,
          listViewByScoped,
          setListViewByScoped,
          viewDefault,
          setViewDefault,
          setFilterViewDefault,
          isMapView,
          setIsMapView,
          handleFindView,
          setIsHandleParams,
          setIsApplyMetric,
          viewLoading,
          tempReport,
          setTempReport,
          isFecthTableEvent,
          segmentSticky,
          setSegmentSticky,
          disableSort,
          reloadTable,
          setReloadTable,
          tempScopedReport,
          setTempScopedReport,
          listChartSegmentApply,
          setListChartSegmentApply,
          isWebConversion,
          dimensionEvents,
          isDraft,
          reportView,
          defaultConfig,
          isSaveReport,
          primaryObject,
          secondaryObject,
          salesConversionName
     };
     if (recordProfile && !isFecthTableEvent) return;

     const titleStyle = ['Event Details', 'Conversion Events', 'Session Details', 'User Details', 'People Details'];
     return (
          <div className="analytics not-container">
               {conversionNameLoading ? (
                    <div className="page-loading-container">
                         <CenterSpinner />
                    </div>
               ) : (
                    <EventExplorerContext.Provider value={contextValue}>
                         <div className="report-header d-flex align-items-center justify-content-between">
                              <p className="report-title">{title}</p>
                              <div className="d-flex align-items-center">
                                   {!recordProfile && (
                                        <>
                                             <div className={`header-item ${titleStyle.includes(title) ? 'no-scope-dropdown' : ''}`}>
                                                  <AddSegments listSegmentApplyOff={listSegmentApplyOff} />
                                             </div>
                                             {reportType && (
                                                  <div className="header-item">
                                                       <ScopeDropdown />
                                                  </div>
                                             )}
                                             <div className="header-item">
                                                  <CustomDatePicker />
                                             </div>
                                        </>
                                   )}
                              </div>
                         </div>
                         
                         {showNewNavigation && (
                              <CSubheader className="main-breadcrumb justify-content-between">
                                   <BreadcrumbRouter
                                        className="border-0 c-subheader-nav m-0 px-4 px-md-3 d-flex align-items-center"
                                        routes={routes}
                                   />
                              </CSubheader>
                         )}

                         {showChart && <ChartReport />}

                         <MenuReport />
                         <ReportTableV2 />
                    </EventExplorerContext.Provider>
               )}
          </div>
     );
};

EventExplorer.propTypes = {
     title: PropTypes.string,
     reportName: PropTypes.string,
};

export default EventExplorer;
