import CIcon from '@coreui/icons-react';
import { CButton, CHeader, CHeaderNav, CLink } from '@coreui/react';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
     setFlexibleModal,
     setFlexibleMultiModal,
     setOverlay,
     setRuleHaveEditting,
     setRuleMultiHaveEditing,
     setUnsavedChanges,
} from '../../../actions/common';
import { setNumberOfHistories, setPublishingVersion } from '../../../actions/pusher';
import {
     resetAllPreview,
     setAccountPreview,
     setDomainPreview,
     setDomainsPreview,
     setListenerPreview,
     setShowConfigurationRequire,
     setShowPopupDomain,
     setShowPublishPopup,
     setTogglePreviewPopup,
     setVersionPublish,
} from '../../../actions/subscriber';
import { callTokenApi } from '../../../apiCaller';
import { API_VERSION_PUBLISH, SUBSCRIBER_MY_ACCOUNT_PATH, SUBSCRIBER_PATH } from '../../../constants';
import GlobalMessage from './GlobalMessage';
import PublishVersionModal from './PublishVersionModal';
import SelectDomainModal from './SelectDomainModal';
import ModalConfigurationRequired from '../../../components/cms/subscriber/listener-settings-v2/ModalConfigurationRequired';
import GetStarted from './GetStarted';
import PreviewPublishPopup from './PreviewPublishPopup';
import CHeaderNavItem from '../../../components/migration/CHeaderNavItem';

const SubscriberNewHeader = (props) => {
     const { previewShow } = props;
     const { activeAccount, versionPublish, numberOfHistories, showPublishPopup, showPopupDomain, showConfigurationRequired, accordion } =
          useSelector((state) => state.subscriber);
     const { listeners: listenersLoading, account: accountLoading } = useSelector((state) => state.subscriber.loadings);
     const { running: previewRunning } = useSelector((state) => state.subscriber.preview);
     const location = useLocation();
     const history = useHistory();
     const dispatch = useDispatch();
     const theme = useSelector((state) => state.theme);
     const { overlay, edittingRule, flexibleMultiModal } = theme;
     const { showHeader } = useSelector((state) => state.subscriber.newLayout);
     const headerClass = classNames({ 'open-dropdown': overlay });
     const showMyAccount = Object.entries(SUBSCRIBER_MY_ACCOUNT_PATH).some(([_, value]) => location.pathname.includes(value));

     const togglePublishModal = () => {
          setTimeout(() => {
               if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
                    toast.warn('Domain settings incomplete. This is vital for your website tracking to work.');
                    history.push(SUBSCRIBER_PATH.WEBSITE);
               } else {
                    dispatch(setShowPublishPopup(!showPublishPopup));
               }
          }, 0);
     };

     const toggleClosePopupConfigurationRequire = () => {
          dispatch(setShowConfigurationRequire({ show: false, listener: accordion }));
     };

     const previewTogglePublishModal = () => {
          if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
               document.getElementById('togglePopupPublish').click();
               toast.warn('Domain settings incomplete. This is vital for your website tracking to work.');
               history.push(SUBSCRIBER_PATH.WEBSITE);
          } else {
               dispatch(setOverlay(false));
               dispatch(setShowPublishPopup(!showPublishPopup));
          }
     };

     const onAccept = (values, { setSubmitting, setValues }) => {
          if (values.name.trim() === '') {
               toast.warn('Name not empty!');
          } else {
               if (activeAccount && versionPublish.version) {
                    values.versionId = versionPublish.version.id;
                    values.accountId = activeAccount.id;
                    callTokenApi(API_VERSION_PUBLISH, 'POST', values)
                         .then((response) => {
                              if (response.status === 200) {
                                   toast.success(response.data.message);
                                   dispatch(setNumberOfHistories(0));
                                   dispatch(setPublishingVersion(response.data.version));
                                   dispatch(
                                        setVersionPublish({
                                             version: '',
                                             isPublish: 'publishing',
                                        }),
                                   );
                              } else {
                                   toast.error(response.data.message);
                              }
                         })
                         .finally(() => {
                              setValues({
                                   name: '',
                                   description: '',
                              });
                              setSubmitting(false);
                              dispatch(setShowPublishPopup(!showPublishPopup));
                              dispatch(resetAllPreview(activeAccount.id));
                         });
               } else {
                    setSubmitting(false);
                    dispatch(setNumberOfHistories(0));
                    toast.error('Latest version has already published');
                    dispatch(setShowPublishPopup(!showPublishPopup));
                    dispatch(resetAllPreview(activeAccount.id));
               }
          }
     };

     const previewOnClick = () => {
          if (activeAccount.numberOfDomains < 1 && !showPublishPopup) {
               toast.warn('Domain settings incomplete. This is vital for your website tracking to work.');
               history.push(SUBSCRIBER_PATH.WEBSITE);
          } else {
               if (previewRunning) {
                    dispatch(setTogglePreviewPopup(true));
               } else {
                    if (activeAccount.numberOfDomains === 1) {
                         previewOnAccept();
                    } else {
                         dispatch(setShowPopupDomain(true));
                    }
               }
          }
     };

     const previewOnAccept = () => {
          dispatch(setTogglePreviewPopup(true));
          dispatch(setShowPopupDomain(false));
          dispatch(setAccountPreview({ accountId: activeAccount.id }));

          const newInitDomains = activeAccount.domains ? JSON.parse(activeAccount.domains) : [];
          dispatch(setDomainsPreview(newInitDomains));
          if (newInitDomains && newInitDomains.length === 1) {
               dispatch(setDomainPreview(newInitDomains[0].name));
          }
     };

     const cancelSelecDomain = () => {
          dispatch(setListenerPreview(''));
          dispatch(setShowPopupDomain(false));
     };

     const onClickVersions = () => {
          dispatch(setFlexibleModal({ show: false, component: '', ruleId: null }));

          const newMultiModals = [...flexibleMultiModal];
          const currentModalIndex = newMultiModals.findIndex((modal) => modal.show);
          if (currentModalIndex > -1) {
               newMultiModals[currentModalIndex] = {
                    ...newMultiModals[currentModalIndex],
                    show: false,
               };

               dispatch(setFlexibleMultiModal(newMultiModals[currentModalIndex]));
          }
          dispatch(setRuleMultiHaveEditing({}));
          dispatch(setRuleHaveEditting({ show: false, showLv2: false, type: '', typeLv2: '' }));
          dispatch(setUnsavedChanges(false));

          if (edittingRule && edittingRule.show) {
               setTimeout(() => {
                    history.push(SUBSCRIBER_PATH.VERSIONS.replace(':secondId', activeAccount.secondId));
               }, 0);
          }
     };

     return (
          <CHeader className={`new-header ${headerClass}`} position="sticky">
               {!showMyAccount && activeAccount.id && !listenersLoading && !accountLoading && showHeader && (
                    <>
                         <GetStarted />
                         <GlobalMessage />
                    </>
               )}

               {activeAccount.id && (
                    <div className="main-nav ml-auto">
                         {showHeader && (
                              <CHeaderNav className="d-flex align-items-center">
                                   {/* <CHeaderNavItem>
                                        <ToursAndConfigure />
                                   </CHeaderNavItem> */}

                                   <CHeaderNavItem className="pl-6 nav-item-publish">
                                        {versionPublish.isPublish === 'enable' || parseInt(numberOfHistories) > 0 ? (
                                             <CButton className={`btn-brand`} color="primary" onClick={togglePublishModal}>
                                                  <span>PUBLISH</span>
                                                  <CIcon icon="cil-send" className="ml-2" />
                                             </CButton>
                                        ) : (
                                             <CButton className={`btn-brand`} color="secondary" disabled>
                                                  <span>PUBLISH</span>
                                                  <CIcon icon="cil-send" className="ml-2" />
                                             </CButton>
                                        )}
                                        {
                                             <>
                                                  <div className="my-0 position-relative flex-column">
                                                       {parseInt(numberOfHistories) > 0 ? (
                                                            <span className="text-unpublished text-danger">
                                                                 {parseInt(numberOfHistories) + ' unpublished changes '}
                                                            </span>
                                                       ) : (
                                                            <span className="text-unpublished text-secondary">0 unpublished changes</span>
                                                       )}
                                                       <CIcon icon="icon-info" className="hover-show-tooltip" height={14}></CIcon>
                                                       <div className="block-tooltip">
                                                            <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                                            <div className="block-tooltip-text">
                                                                 <p>
                                                                      Changes you make in the interface are accumulated and must be published to go
                                                                      live on your website.
                                                                 </p>
                                                            </div>
                                                       </div>
                                                       <div className="versions">
                                                            <CLink
                                                                 as={NavLink}
                                                                 to={SUBSCRIBER_PATH.VERSIONS.replace(':secondId', activeAccount.secondId)}
                                                                 onClick={onClickVersions}
                                                            >
                                                                 Versions
                                                            </CLink>
                                                       </div>
                                                  </div>
                                             </>
                                        }
                                   </CHeaderNavItem>

                                   {parseInt(numberOfHistories) > 0 && !showPublishPopup ? (
                                        <PreviewPublishPopup togglePublishModal={previewTogglePublishModal} />
                                   ) : (
                                        <CHeaderNavItem className={`nav-item-datalayer text-center`}>
                                             {!previewShow && previewRunning && parseInt(numberOfHistories) === 0 ? (
                                                  <CButton className="box-preview btn-header" onClick={previewOnClick}>
                                                       <CIcon icon="icon-expand" className="mr-2" />
                                                       Active Live Test
                                                       <span className={`pulse-status${previewRunning ? ' online' : ''}`}></span>
                                                  </CButton>
                                             ) : (
                                                  <CButton className="btn-datalayer" onClick={previewOnClick}>
                                                       <CIcon icon="icon-expand" className="mr-2 p-0" />
                                                       <span>TEST MY DATA LAYER</span>
                                                  </CButton>
                                             )}
                                        </CHeaderNavItem>
                                   )}
                              </CHeaderNav>
                         )}
                    </div>
               )}

               <PublishVersionModal show={showPublishPopup} onAccept={onAccept} onCancel={() => dispatch(setShowPublishPopup(false))} />
               {showPopupDomain && <SelectDomainModal show={showPopupDomain} onAccept={previewOnAccept} onClose={() => cancelSelecDomain()} />}
               {showConfigurationRequired.show && (
                    <ModalConfigurationRequired show={showConfigurationRequired.show} onCancel={() => toggleClosePopupConfigurationRequire()} />
               )}
          </CHeader>
     );
};

export default React.memo(SubscriberNewHeader);
