import CIcon from '@coreui/icons-react';
import { CBadge, CButton } from '@coreui/react';
import { CSmartTable } from '@coreui/react-pro';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { signout } from '../../helpers/auth';
import {
     fetchAnAccount,
     fetchMonitoringNotifications,
     handleSetFormCategoriesAll,
     resetAllPreview,
     resetSubscriberReducer,
     setActiveListener,
     setDormantAccount,
     setNumberOffsetFormsCategories,
     setRoutes,
     setShowAccountDormantPopup,
     setShowCreateAccountPopup,
     setShowIgnoredForm,
     setShowIgnoredInventoryForm,
} from '../../actions/subscriber';
import { callTokenApi } from '../../apiCaller';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../components/general/dropdown';
import {
     API_CLIENT_FIND_USER_ACCOUNT,
     AUTH_PATH,
     DORMANT_STATUSES,
     LOCAL_SETUP_INVITED_ACCOUNT,
     LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS,
     LOCAL_SHOW_ONLY_ENABLED_LISTENERS,
     PLATFORM_ACCOUNT_STATUSES,
     SUBSCRIBER_MY_ACCOUNT_PATH,
     SUBSCRIBER_PATH,
} from '../../constants';
import { gtmTrackingChangeLLAccount, useOutsideHandling } from '../../utils';
import EndSession from './header/EndSession';
import { TOURSTEPS } from './TheTour';
import CImg from '../../components/migration/CImg';

const SubscriberNewSidebarFooter = ({ isMinimizeSidebar }) => {
     const { accounts, activeAccount, previewUrl } = useSelector((state) => state.subscriber);
     const { running: previewRunning } = useSelector((state) => state.subscriber.preview);
     const numberOfCommentsTickets = useSelector((state) => state.subscriber.numberOfCommentsTickets);
     const history = useHistory();
     const dispatch = useDispatch();
     const iconRef = useRef(null);
     const theme = useSelector((state) => state.theme);
     const { user } = theme;
     const { showTour, stepTour } = useSelector((state) => state.subscriber.tour);
     const userId = user.id;
     const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
     const [globalDropdownOpen, setGlobalDropdownOpen] = useState(false);
     const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
     const [rectIcon, setRectIcon] = useState({});
     const [endSession, setEndSession] = useState(false);
     const [handleEndSession, setHandleEndSession] = useState(() => () => {});

     const handleRemoveGuideLocalStorage = () => {
          localStorage.removeItem('showAnimationRoadmap');
          localStorage.removeItem('guideGetStarted');
          localStorage.removeItem('installGoals');
          localStorage.removeItem('forCategorization');
          localStorage.removeItem('dataEnrichment');
          localStorage.removeItem('sendingData');
          localStorage.removeItem('customOptional');
          localStorage.removeItem('configureDestination');
          localStorage.removeItem('endRoadmap');
     };

     const changeAccount = (account) => {
          if (account.id !== activeAccount.id) {
               handleRemoveGuideLocalStorage();

               if (DORMANT_STATUSES.includes(account.platformAccountStatus)) {
                    dispatch(setDormantAccount(account));
                    dispatch(setShowAccountDormantPopup(true));
               } else if (previewRunning) {
                    setHandleEndSession(() => () => {
                         dispatch(resetAllPreview(activeAccount.id));
                         setEndSession(false);
                         selectAccountID(account);
                         if (previewUrl) {
                              const openedWindow = window.open(previewUrl.replace('previewLL=iframe', 'previewLL=1'), 'LL Preview');
                              if (openedWindow) {
                                   openedWindow.close();
                              }
                         }
                    });
                    setEndSession(true);
               } else {
                    selectAccountID(account);
               }
               dispatch(setNumberOffsetFormsCategories(0));
               dispatch(handleSetFormCategoriesAll());
               dispatch(setShowIgnoredForm(false));
               dispatch(setShowIgnoredInventoryForm(false));
          }
     };

     const selectAccountID = (account) => {
          // Prevent select same account
          if (account.id !== activeAccount.id) {
               dispatch(setRoutes([])); // Prevent Dashboard from loading twice cause it's gonna fetch data twice
               dispatch(resetSubscriberReducer());
               dispatch(fetchAnAccount(account, accounts)); // Mainly fetch new used items for selected account
               dispatch(fetchMonitoringNotifications(account.id));
               dispatch(setActiveListener(null));
               // dispatch(setActiveDestination(null));

               localStorage.setItem('accountId', account.id);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS);
               localStorage.removeItem('LeaveStepCustomForms');

               history.push(`/${account.secondId}`);

               let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);
               callTokenApi(`${API_CLIENT_FIND_USER_ACCOUNT}${userId}/${account.id}`, 'GET', null).then((response) => {
                    if (response.status === 200) {
                         const { userAccount } = response.data;
                         // Tracking Change Account
                         const userDetails = {
                              userId: user.id,
                              email: user.email,
                         };

                         const _activeAccount = accounts.find((acc) => acc.id === userAccount.accountId);
                         const accountDetails = {
                              accountid: _activeAccount.id,
                              accountname: _activeAccount.name,
                         };
                         gtmTrackingChangeLLAccount(userDetails, accountDetails);
                         if (
                              localSetupInvitedAccount === 'yes' ||
                              (userAccount &&
                                   !userAccount.firstLoginAccount &&
                                   typeof userAccount.firstLoginAccount !== 'undefined' &&
                                   userAccount.firstLoginAccount !== 'undefined')
                         ) {
                              localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
                              // callTokenApi(`${API_CLIENT_ACCOUNT}${activeAccount.accountId}`, 'GET', null);
                              const hash = history.location.hash;
                              if (!hash || hash !== '#payment-method') {
                                   history.push(AUTH_PATH.WELCOME_SCREEN);
                              }
                         }
                    }
               });
          }
     };

     const createAccountBtnClicked = () => {
          handleRemoveGuideLocalStorage();

          window.localStorage.removeItem('isRoadmap' + activeAccount.id);
          if (previewRunning) {
               setHandleEndSession(() => () => {
                    dispatch(resetAllPreview(activeAccount.id));
                    setEndSession(false);
                    dispatch(setShowCreateAccountPopup(true));
                    if (previewUrl) {
                         const openedWindow = window.open(previewUrl.replace('previewLL=iframe', 'previewLL=1'), 'LL Preview');
                         if (openedWindow) {
                              openedWindow.close();
                         }
                    }
               });
               setEndSession(true);
          } else {
               dispatch(setShowCreateAccountPopup(true));
          }
     };
     const clickOutside = useCallback(() => {
          setGlobalDropdownOpen(false);
     }, []); // eslint-disable-line

     useOutsideHandling(iconRef, clickOutside);

     const handleClickIcon = (e) => {
          e.stopPropagation();
          setGlobalDropdownOpen((prev) => !prev);

          const rect = iconRef.current?.getBoundingClientRect();
          setRectIcon(rect);
     };

     const handleClickLink = (url) => {
          setTimeout(() => {
               history.push(url.replace(':secondId', activeAccount.secondId));
          }, 350);
     };

     return (
          <>
               <Dropdown
                    className={`switch-account-settings${showTour && stepTour === TOURSTEPS.listenerAccounts.orderNumber ? ' highlight-item' : ''}`}
                    focusInputSelector=".table-accounts input"
                    forceOpen={showTour && stepTour === TOURSTEPS.listenerAccounts.orderNumber}
               >
                    <DropdownToggle className="switch-account-settings--toggle">
                         {!isMinimizeSidebar && <span className="account-name">{activeAccount?.id ? activeAccount.name : 'Select an account'}</span>}

                         <CIcon ref={iconRef} icon="iconGlobalSettings" className="icon-global-settings" onClick={handleClickIcon} />
                    </DropdownToggle>

                    <DropdownMenu
                         className="switch-account-settings--menu account-select"
                         show={globalDropdownOpen && accountDropdownOpen ? false : null}
                         setDropdownOpen={setAccountDropdownOpen}
                    >
                         <div className={`table-accounts ${accounts.length < 8 ? 'no-filter' : ''}`}>
                              <CSmartTable
                                   items={accounts}
                                   columns={[{ key: 'name', label: 'Account', _style: { width: '100%' }, filter: true, sorter: true }]}
                                   tableFilter
                                   tableFilterPlaceholder="Search accounts"
                                   tableFilterLabel=""
                                   tableProps={{ hover: true }}
                                   sorterValue={{
                                        column: 'name',
                                        state: 'asc',
                                   }}
                                   itemsPerPage={9999}
                                   noItemsLabel={<span style={{ padding: '25px' }}>No filtering results</span>}
                                   scopedColumns={{
                                        name: (account) => {
                                             let isActive = false;
                                             const accountDormant =
                                                  account.platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT
                                                       ? 'Dormant (permanent)'
                                                       : account.platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.DORMANT_TEMPORARY
                                                         ? 'Dormant (temporary)'
                                                         : '';

                                             if (activeAccount.id) {
                                                  isActive = account.id === activeAccount.id;
                                             }
                                             return (
                                                  <td key={account.id} onClick={() => changeAccount(account)}>
                                                       <DropdownItem style={{ alignItems: 'center' }}>
                                                            <span className={isActive ? 'active account-name' : 'account-name'}>
                                                                 {account.name}
                                                                 {accountDormant && <span className="label-dormant">{accountDormant}</span>}
                                                            </span>
                                                            {isActive && <CBadge className="label-current">CURRENT</CBadge>}
                                                       </DropdownItem>
                                                  </td>
                                             );
                                        },
                                   }}
                              />
                         </div>
                         <DropdownItem tag="div" className="button-wrapper remove-hover" role="menuitem">
                              <CButton className="add-account" variant="outline" onClick={createAccountBtnClicked}>
                                   Add new account
                              </CButton>
                         </DropdownItem>
                    </DropdownMenu>

                    <Dropdown className="global-settings" forceOpen={showTour && stepTour === TOURSTEPS.accessSettings.orderNumber}>
                         <DropdownMenu className="global-settings--menu" show={globalDropdownOpen} style={{ left: `${rectIcon.left + 20}px` }}>
                              <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.WEBSITE)}>
                                   <CIcon icon="iconManageWebsite" />
                                   <div className="global-settings--content">
                                        <p className="global-settings--title">Website</p>
                                        <p className="global-settings--description">Domains & Script Placement</p>
                                   </div>
                              </DropdownItem>
                              <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.USERS)}>
                                   <CIcon icon="iconManageUsers" />
                                   <div className="global-settings--content">
                                        <p className="global-settings--title">Users</p>
                                        <p className="global-settings--description">Manage Access</p>
                                   </div>
                              </DropdownItem>
                              <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.ACCOUNT_SETTINGS)}>
                                   <CIcon icon="iconGlobalSettings" />
                                   <div className="global-settings--content">
                                        <p className="global-settings--title">Account Settings</p>
                                        <p className="global-settings--description">Name, Timezone & Timestamps</p>
                                   </div>
                              </DropdownItem>
                              <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.PLAN_USAGE)}>
                                   <CIcon icon="iconPlansUsage" />
                                   <div className="global-settings--content">
                                        <p className="global-settings--title">Plans & Usage</p>
                                        <p className="global-settings--description">Subscription & Data Use</p>
                                   </div>
                              </DropdownItem>
                              <DropdownItem onClick={() => handleClickLink(SUBSCRIBER_PATH.BILLING_INVOICES)}>
                                   <CIcon icon="iconBillingInvoice" />
                                   <div className="global-settings--content">
                                        <p className="global-settings--title">Billing & Invoices</p>
                                        <p className="global-settings--description">Payments & Terms</p>
                                   </div>
                              </DropdownItem>
                         </DropdownMenu>
                    </Dropdown>
               </Dropdown>

               <Dropdown
                    className={`profile-settings${showTour && stepTour === TOURSTEPS.personalLogin.orderNumber ? ' highlight-item' : ''}`}
                    forceOpen={showTour && stepTour === TOURSTEPS.personalLogin.orderNumber}
               >
                    <DropdownToggle className="profile-settings--toggle" caret={false}>
                         <div className="avatar avatar1">
                              <CImg
                                   src={user.avatar && user.avatar !== 'false' ? user.avatar : '/user-default-avatar.png'}
                                   className="avatar-img"
                                   alt="avatar"
                              />
                         </div>
                         {!isMinimizeSidebar && (
                              <>
                                   <div className="account-user-info">
                                        <span className="account-user-info--name">{user ? `${user.firstName} ${user.lastName}` : ''}</span>
                                        <p className="account-user-info--email">{user ? user.email : ''}</p>
                                   </div>
                                   <div className="icon-profile-settings">
                                        <CIcon icon="iconProfileSettings" />
                                   </div>
                              </>
                         )}
                    </DropdownToggle>
                    <DropdownMenu
                         className="profile-settings--menu"
                         show={globalDropdownOpen && profileDropdownOpen ? false : null}
                         setDropdownOpen={setProfileDropdownOpen}
                    >
                         <DropdownItem as={Link} to={SUBSCRIBER_MY_ACCOUNT_PATH.PROFILE}>
                              <CIcon icon="iconProfile" /> My profile
                         </DropdownItem>
                         <DropdownItem as={Link} to={SUBSCRIBER_MY_ACCOUNT_PATH.EMAIL_NOTIFICATIONS}>
                              <CIcon icon="iconEmail" /> Email notifications
                         </DropdownItem>
                         <DropdownItem as={Link} className="my-ticket" to={SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS}>
                              <CIcon icon="iconTickets" /> My tickets
                              {numberOfCommentsTickets > 0 && (
                                   <CBadge color="info" style={{ lineHeight: '18px', padding: '0 5px', height: '18px' }} className="mfs-auto">
                                        {numberOfCommentsTickets}
                                   </CBadge>
                              )}
                         </DropdownItem>
                         <DropdownItem className="sign-out text-danger" onClick={signout}>
                              <CIcon icon="iconSignOut" /> Sign out
                         </DropdownItem>
                    </DropdownMenu>
               </Dropdown>

               <EndSession show={endSession} onAccept={handleEndSession} onCancel={() => setEndSession(false)} />
          </>
     );
};

export default React.memo(SubscriberNewSidebarFooter);
