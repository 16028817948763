import React, { useContext } from 'react';
import CIcon from '@coreui/icons-react';
import { EventExplorerContext } from '../../EventExplorer';
import { draftSessionStorageReport } from '../../../../../../utils';
import { useSelector } from 'react-redux';
import { REPORT_NAMES } from '../../../../../../constants';
import CTooltip from '../../../../../migration/CTooltip';
import { getOriginalField } from '../../../../../../utils';

function CustomHeaderCell({
     props,
     dimensions,
     arrShowShortIds,
     showLock,
     lock,
     showShortId,
     sort,
     disableSort,
     setLock,
     setShowShortId,
     setOpenTooltip,
     filterMetric,
}) {
     const { field, title } = props;
     const showEyeIcon = dimensions[0] && dimensions.some((item) => arrShowShortIds.includes(item.key) && item.key === field);
     const { setSort, reportName } = useContext(EventExplorerContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     const handleClickSort = (field) => {
          if (field === 'viewDetail' || disableSort) {
               return;
          }
          let newSort
          if (sort.fieldName !== field) {
               newSort = { fieldName: field, isDesc: true };
          } else {
               if (sort.isDesc) {
                    newSort = { fieldName: field, isDesc: false };
               }
               else {
                    newSort = {};
               }
          }

          setSort(newSort);
          draftSessionStorageReport({
               accountId: activeAccount.id, reportName, fieldUpdate: {
                    sort: newSort
               }
          });
     };

     const handleClickLock = (field, event) => {
          event.stopPropagation();
          if (lock[field]) {
               setLock((old) => {
                    return { ...old, [field]: !lock[field] };
               });
          } else {
               setLock((old) => {
                    return { ...old, [field]: true };
               });
          }
     };

     const handleShowShortId = (e, field) => {
          let newShowShortId = [...showShortId];

          if (showShortId.includes(field)) {
               newShowShortId = [...showShortId].filter((item) => item !== field);
          } else {
               newShowShortId.push(field);
          }

          e.stopPropagation();
          setShowShortId(newShowShortId);
     };

     const currentMetricField = filterMetric && filterMetric.find((item) => getOriginalField(field) === item.value);
     
     return (
          <span className="k-cell-inner table-head">
               <span className="k-link">
                    <span onClick={() => handleClickSort(field)}>
                         {[REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY].includes(reportName) && currentMetricField && currentMetricField.description ?
                              (
                                   <CTooltip
                                        content={currentMetricField.description}
                                   >
                                        <span>{title}</span>
                                   </CTooltip>
                              ) :
                              (<>{title}</>)
                         }

                    </span>
                    <div
                         className={`lock-column ${showLock[field] && 'show'} ${lock[field] && 'active'}`}
                         onClick={(event) => handleClickLock(field, event)}
                    >
                         <CIcon icon="iconLock" className="icon-lock"></CIcon>
                    </div>
                    {showEyeIcon && (
                         <div
                              onClick={(e) => {
                                   setOpenTooltip(false);
                                   handleShowShortId(e, field);
                              }}
                         >
                              <CIcon icon={showShortId.includes(field) ? 'iconEyeRegularSlash' : 'iconEyeRegular'}></CIcon>
                         </div>
                    )}

                    {sort.fieldName === field && !disableSort && <CIcon icon="iconDropdown" className={`icon-sort ${sort.isDesc ? 'desc' : 'asc'}`}></CIcon>}
               </span>
          </span >
     );
}

export default CustomHeaderCell;
